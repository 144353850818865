import './slider.scss';
import 'swiper/css';

import dinosaur from '../../resources/img/Dinosaur.jpg';
import plant2 from '../../resources/img/plant2.png';
import Spinner from '../spinner/Spinner'
import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import ErrorMassage from '../errorMassade/ErrorMassage'

import useCollectionService from '../../servises/SrevisesCollection'

const Slider = () => {
    const {loading, error, getCollection, clearError} = useCollectionService();
    
    const [data, setData] = useState({});
    const [acriveButton, setAcriveButton] = useState(0);
    //const [loading, setLoading] = useState(true);
    const refToggleMenu = useRef(null);
    const sectionSliderBattonsMob = useRef(null);
    const loadingData = (e)=>{
        e.preventDefault(); 
       // console.log(e.target.getAttribute('data-href'))
        let dataHref = e.target.getAttribute('data-href');
        // if(dataHref){
        //     dataHref = Number(dataHref);
        // }
        
        getCollectionF(dataHref);
        setAcriveButton(dataHref)
        // refToggleMenu.current.classList.toggle('active');
        // sectionSliderBattonsMob.current.classList.toggle('active');
    }

   


    useEffect( ()=>{    
         getCollectionF(0);
    }, []);

    const getCollectionF = async (id=0)=>{
        clearError();
        const res = await getCollection(id);
        //console.log(res)
        setData(data=>res)
        //setLoading(false);
    }

    if(error){
        return(
            <ErrorMassage/>
        )
    }
    
    if(loading){
        return(
            <>
            <section id="dinozavry" className="section-slider section-slider-pr-5634534"></section>
            <Spinner/>
            </>
            
        ) 
    }
    else{
        let imgs1 = [];
        let imgs2 = [];
        if(window.innerWidth>767){
            imgs1 = data.imgs1;
            imgs2 = data.imgs2;
        }
        else{
            imgs1 = data.imgs2;
            imgs2 = data.imgs1;
        }
        

        const itemsP = (mass)=>{
            if(Array.isArray(mass)){
                return(
                    mass.map((elem, i)=>{
                        return (
                            <p className='mob-or-1' key={i}>{elem}</p>
                        )
                    })
                )
            }
            else{
                return null;
            }
           
        }
        
        const firstPs = itemsP(data.firstPs);
        const textPodbigTitle2 = itemsP(data.textPodbigTitle2);
        const toPs = itemsP(data.toPs);
        
        const mobMenuBattons = () =>{
            refToggleMenu.current.classList.toggle('active');
            sectionSliderBattonsMob.current.classList.toggle('active');
        }
        let activMobTex = null;
        if(data.sectionSliderBatton){
            activMobTex = data.sectionSliderBatton[acriveButton].title;
            // console.log(data.sectionSliderBatton[0].title)
        }   
        // console.log(data.sectionSliderBatton)

        return (
        
            <section id="dinozavry" className="section-slider section-slider-pr-5634534">
                <div className="section-slider-fon-1">
                    <img src={plant2} alt="" />
                </div>
                <div className="container">
                    <div className="title-st-1">{data.bigTitle}</div>
                    <div className="section-slider-battons slider-big-dannie-slidov slider-big-dannie-slidov-mob">
                       
                        <div ref={refToggleMenu} onClick={mobMenuBattons} className="section-slider-batton section-slider-batton-open slider-big-dannie-slidov-item">
                            <span>{ activMobTex }</span>
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <path d="M2.5 5L8.5 11L14.5 5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </div>
                        <div ref={sectionSliderBattonsMob} className="section-slider-battons-mob">
                            <div className="section-slider-battons-mob-fon"><img src={dinosaur} alt="" /></div>
                            <Buttons acriveButton={acriveButton} loadingData={loadingData} sectionSliderBatton={data.sectionSliderBatton}/>
                        </div> 
                       
                    </div>
                    <div className="section-slider-content">
                        <div className="section-slider-content-left text-blok-sl">
                            
                            <div className="title-st-2 mob-or--8">
                                <div className="icon">
                                    <svg style={{display:'none'}} xmlns="http://www.w3.org/2000/svg" width="66" height="56" viewBox="0 0 66 56" fill="none">
                                        <path d="M66 27.945C66 40.9194 56.3539 54.0186 36.0462 55.89C19.8 57.387 0 43.3786 0 27.945C0 12.5114 20.3443 0 36.0462 0C51.748 0 66 12.5114 66 27.945Z" fill="#006B11"/>
                                        <path d="M29.939 14C28.2976 14 26.9456 15.355 26.9456 17V28.813L26.291 28.125L26.0416 27.906C25.4866 27.3522 24.7353 27.0414 23.9521 27.0414C23.169 27.0414 22.4177 27.3522 21.8627 27.906C21.3102 28.4622 21 29.2151 21 30C21 30.7849 21.3102 31.5378 21.8627 32.094V32.125L30.0328 40.219L30.0947 40.25L30.1256 40.313C31.5615 41.4038 33.3129 41.996 35.1147 42H36.8309C37.9044 42.0022 38.9678 41.792 39.9599 41.3813C40.9521 40.9706 41.8536 40.3675 42.6127 39.6067C43.3717 38.8459 43.9733 37.9424 44.383 36.948C44.7927 35.9536 45.0023 34.8878 45 33.812V26C45 24.355 43.6469 23 42.0065 23C41.5815 23 41.1883 23.117 40.8211 23.281C40.4939 21.981 39.3135 21 37.9215 21C37.1572 21 36.4557 21.3 35.9259 21.781C35.3804 21.2815 34.6691 21.0032 33.9303 21C33.5893 21.0043 33.2517 21.0679 32.9325 21.188V17C32.9325 15.355 31.5804 14 29.939 14ZM29.939 16C30.4928 16 30.9368 16.445 30.9368 17V28H32.9325V24C32.9325 23.445 33.3765 23 33.9303 23C34.484 23 34.9281 23.445 34.9281 24V28H36.9237V24C36.9237 23.445 37.3677 23 37.9215 23C38.4753 23 38.9193 23.445 38.9193 24V28H41.0087V26C41.0087 25.445 41.4528 25 42.0065 25C42.5593 25 43.0044 25.445 43.0044 26V33.813C43.0044 37.277 40.2873 40 36.8299 40H35.1157C33.6539 40 32.3906 39.477 31.3419 38.687L23.2657 30.687C22.8216 30.242 22.8216 29.757 23.2657 29.312C23.7107 28.867 24.1936 28.867 24.6376 29.312L28.9412 33.625V17C28.9412 16.445 29.3852 16 29.939 16Z" fill="white"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="56" viewBox="0 0 65 56" fill="none">
                                        <path d="M65 27.945C65 40.9194 55.5 54.0186 35.5 55.89C19.5 57.387 0 43.3786 0 27.945C0 12.5114 20.036 0 35.5 0C50.964 0 65 12.5114 65 27.945Z" fill="#006B11"/>
                                        <path d="M32.5 42C39.2279 42 43 37.3061 43 29.85C43 22.394 37.1804 15 32.5 15C27.8196 15 22 22.394 22 29.85C22 37.3061 25.7721 42 32.5 42Z" stroke="white" strokeWidth="2.13333"/>
                                        <path d="M31 38C31.5304 38 32.0391 37.7893 32.4142 37.4142C32.7893 37.0391 33 36.5304 33 36C33 35.4696 32.7893 34.9609 32.4142 34.5858C32.0391 34.2107 31.5304 34 31 34C30.4696 34 29.9609 34.2107 29.5858 34.5858C29.2107 34.9609 29 35.4696 29 36C29 36.5304 29.2107 37.0391 29.5858 37.4142C29.9609 37.7893 30.4696 38 31 38Z" fill="white"/>
                                        <path d="M35.5 28C36.163 28 36.7989 27.7366 37.2678 27.2678C37.7366 26.7989 38 26.163 38 25.5C38 24.837 37.7366 24.2011 37.2678 23.7322C36.7989 23.2634 36.163 23 35.5 23C34.837 23 34.2011 23.2634 33.7322 23.7322C33.2634 24.2011 33 24.837 33 25.5C33 26.163 33.2634 26.7989 33.7322 27.2678C34.2011 27.7366 34.837 28 35.5 28Z" fill="white"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.5 33C27.8978 33 28.2794 32.842 28.5607 32.5607C28.842 32.2794 29 31.8978 29 31.5C29 31.1022 28.842 30.7206 28.5607 30.4393C28.2794 30.158 27.8978 30 27.5 30C27.1022 30 26.7206 30.158 26.4393 30.4393C26.158 30.7206 26 31.1022 26 31.5C26 31.8978 26.158 32.2794 26.4393 32.5607C26.7206 32.842 27.1022 33 27.5 33Z" fill="white"/>
                                        </svg>
                                </div>
                                <div className="title-st-2-text">{data.zelTitle}</div>
                            </div>
                            <div className="mob-or--7 cas-100df">
                            {
                                firstPs
                            }
                            </div>
                            
                            
                            <div className="section-slider-content-blok mob-or--6">
                                <div className="title-st-3">{data.bigTitle2}</div>
                                {textPodbigTitle2}
                            </div>
    
                            <div className="section-slider-content-blok-2 mob-or--5">
                                <p>{data.text3}</p>
                                
                                <div className="section-slider-content-blok-2-left">
                                    <div className="title-st-3">{data.bigTitle3}</div>
                                    <p>{data.textPodbigTitle3}</p>
                                </div>
                                <div className="section-slider-content-blok-2-right">
                                    <div className="title-st-3">{data.bigTitle4}</div>
                                    <p>{data.textPodbigTitle4}</p>
                                </div> 
                               
                            </div>
    
                            <div className="section-slider-slider section-slider-slider-buttons mob-or--6"> 
                                <div className="section-slider-slider-fon"></div>
                                <Swwiper slidy={imgs1} />
                            </div>
                           
                        </div>
                        <div className="section-slider-content-right section-slider-content-right-st-pl">
                            <div className="section-slider-slider-2 section-slider-slider-buttons section-slider-slider-buttons-2"> 
                                <div className="section-slider-slider-fon-2"></div>
                                <Swwiper slidy={imgs2} />
                                
                            </div>
                            <div className="text-blok-sl-p">
                                   {toPs}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
        
    }
    
}

const Buttons = ({sectionSliderBatton, loadingData, acriveButton})=>{
    if(Array.isArray(sectionSliderBatton)){
        return(
            sectionSliderBatton.map((elem, i)=>{
                let cl06 = 'section-slider-batton slider-big-dannie-slidov-item';
                if(i == acriveButton){
                    cl06 = cl06 + ' active';
                } 
                return(
                    <div data-href={elem.url} key={i} onClick={loadingData} className={cl06} >{elem.title}</div>
                )
            }) 
        )
    }
    else{
        return null;
    }
    
}

const Swwiper = ({slidy}) => {

    if(Array.isArray(slidy)){
        const slides = slidy.map((elem, i)=>{
            return(
                <SwiperSlide key={i}><img src={elem} alt="" /></SwiperSlide>
            )
        });
        
        return (
          <Swiper
            modules={[Navigation, Pagination ]}
            spaceBetween={5}
            slidesPerView={1}
            navigation={{
                nextEl: ".swiper-button-next-home",
                prevEl: ".swiper-button-prev-home",
            }}
            pagination={{
                el: '.swiper-pagination',
                clickable: true
            }}
          >
            {slides}
    
                <div className="swiper-button-prev-home">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <path opacity="0.7" d="M60 30.0058C60 46.5712 46.5685 60 30 60C13.4315 60 0 40.1733 0 30.0058C0 13.4405 13.4315 0.011621 30 0.011621C43.7288 -0.496772 60 15.7713 60 30.0058Z" fill="white"/>
                            <path d="M32 24L26 30L32 36" stroke="#383838" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                </div>
                <div className="swiper-button-next-home">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <path opacity="0.7" d="M0 30.0058C0 46.5712 13.4315 60 30 60C46.5685 60 60 40.1733 60 30.0058C60 13.4405 46.5685 0.011621 30 0.011621C16.2712 -0.496772 0 15.7713 0 30.0058Z" fill="white"/>
                            <path d="M28 24L34 30L28 36" stroke="#383838" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                </div>
            <div className="swiper-pagination swiper-pagination-1 swiper-pagination-2"></div>
          </Swiper>
        );
    }
    else{
        return null;
    }

    
  };

export default Slider;