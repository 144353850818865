import { useState, useCallback } from "react";

export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
 
    const reqest = useCallback(async (url, methog = 'GET', body = null, headers = {'Content-Type' : 'application/json'})=>{
        setLoading(true);

        try{
            const response = await fetch(url, {methog, body, headers})
            if(!response.ok) {
                throw new Error(`Could not fetch ${url}, status: ${response.status}`);
            }
            const data = await response.json();
            setLoading(false);
            return data;
            
        } catch(e){
            
            setLoading(false);
            setError(e.message);
            throw e;
        }
    }, [])
    
    const clearError = useCallback(()=> setError(null), []);

    return {loading, reqest, error, clearError}
}  