import Slider from "../slider/Slider";
import Fon from "../fon/Fon";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";
const App = () => {
    return (
        <>
            <ErrorBoundary>
                <Slider/>
            </ErrorBoundary>
            
            <Fon/> 
        </>
    )
}

export default App;