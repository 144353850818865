import img from '../../resources/img/fon.png';
import imgMob from '../../resources/img/fon-mob.png';
import { useState, useEffect, useRef } from 'react';

const Fon = () => {

    const ref = useRef(null);
    useEffect(()=>{
       
			if(ref.current.getAttribute('data-img-desk') || ref.current.getAttribute('data-img-mob') ){
                if(window.innerWidth>767){
                    if(ref.current.getAttribute('data-img-desk')){
                        ref.current.style.backgroundImage=`url(${ref.current.getAttribute('data-img-desk')})`;
                    }
                }
                else{
                    if(ref.current.getAttribute('data-img-mob')){
                        ref.current.style.backgroundImage=`url(${ref.current.getAttribute('data-img-mob')})`;
                    }
                }
			}
		
    }, [])

    return (
        <div className='section-slider-section-slider-2-fon' >
            <div ref={ref} className='bs-st sizejsimg' data-img-desk={img} data-img-mob={imgMob} ></div>
        </div>
    )
}

export default Fon;