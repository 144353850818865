import { useHttp } from "../hooks/http.hook";
const useCollectionService = () => {
    const {loading, reqest, error, clearError}=useHttp();

    const _apiBase = '/api/v1/collection';
    
    const getCollection = async (id) => {
        const res = await reqest(`${_apiBase}/${id}`);
        
        //return res;
        //console.log(res)
        return _transformCharacter(res);
    }

    const _transformCharacter = (res) =>{
        return {
            bigTitle:res[0]['bigTitle'],
            sectionSliderBatton:res[0]['sectionSliderBatton'],
            zelTitle: res[0]['zelTitle'],
             firstPs: res[0]['firstPs'],
             bigTitle2:res[0]['bigTitle2'],
             textPodbigTitle2: res[0]['textPodbigTitle2'], 
             text3:res[0]['text3'],
             bigTitle3: res[0]['bigTitle3'],
             textPodbigTitle3: res[0]['textPodbigTitle3'],

             bigTitle4: res[0]['bigTitle4'],
             textPodbigTitle4: res[0]['textPodbigTitle4'],
             imgs1:res[0]['imgs1'],
             imgs2:res[0]['imgs2'],
             toPs:res[0]['toPs']
        }
    }
    
    return {loading, error, getCollection, clearError}
}
 
export default useCollectionService;